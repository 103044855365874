<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item
        :label="$t('fee.plan')"
        prop="plan"
        :rules="[{ required: true, message: 'The field is required'}]"
      >
        <el-select v-model="form.plan" placeholder="Select plan" class="w-100">
          <el-option v-for="item in plans" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('fee.scheme')"
        prop="scheme"
        :rules="[{ required: true, message: 'The field is required'}]"
      >
        <el-select v-model="form.scheme" placeholder="Select scheme" class="w-100">
          <el-option v-for="item in schemes" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('fee.start')"
        prop="start"
        :rules="[{ required: true, message: 'The field is required'}]"
      >
        <el-date-picker
          type="date"
          v-model="form.start"
          value-format="yyyy-MM-dd"
          format="MM/dd/yyyy"
          placeholder="Pick a day"
          class="w-100"
        ></el-date-picker>
      </el-form-item>
      <el-form-item
        :label="$t('fee.fee')"
        prop="fee"
        :rules="[{ required: true, message: 'The field is required'}]"
      >
        <el-input type="text" v-model="form.fee"></el-input>
      </el-form-item>

      <el-button type="primary" @click="(!data)?submitForm('form'):updateForm('form')">Save data</el-button>
    </el-form>
  </div>
</template>

<script>
import plan from "@/services/api/plan";
import scheme from "@/services/api/scheme";
import fee from "@/services/api/fee";

export default {
  props: ["data"],
  data() {
    return {
      plans: [],
      schemes: [],
      form: {
        id: null,
        plan: null,
        scheme: null,
        start: null,
        fee: null,
        corporate: this.data
      }
    };
  },
  mounted() {
    plan.get().then(response => {
      this.plans = response;
    });
    scheme.get().then(response => {
      this.schemes = response;
    });
    //
    // console.log(this.data);
    if (this.data) {
      this.form.id = this.data.id;
      this.form.plan = this.data.planId;
      this.form.scheme = this.data.schemeId;
      this.form.start = this.data.startOn;
      this.form.fee = this.data.fee;
      this.form.corporate = this.data.corporateRef;
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          fee
            .create(this.form)
            .then(response => {
              this.$message({
                message: "Create success fee",
                type: "success",
                customClass: "message-success"
              });
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          fee
            .update(this.form)
            .then(response => {
              this.$message({
                message: "Update success fee",
                type: "success",
                customClass: "message-success"
              });
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>